<template>
  <svg
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M15.9511 6.93907V2.79189C15.9511 2.48505 15.8311 2.19123 15.6175 1.97402L14.006 0.338641C13.7924 0.121795 13.5026 0 13.2003 0H3.4181C2.78895 0 2.1844 0.389399 2.1844 1.02801V7.71008C0.92576 7.71008 0 7.97451 0 9.25209V13.2999C0 13.6194 0.254933 13.8781 0.569683 13.8781H2.53192V17.3477C2.53192 17.9863 2.91555 17.9902 3.54469 17.9902H14.6852C15.3143 17.9902 15.6979 18.1148 15.6979 17.4762V13.8781H17.6602C17.9749 13.8781 18 13.6836 18 13.3641V9.25209C18 7.97451 17.2098 6.93907 15.9511 6.93907ZM13.6724 15.9342H4.55746V12.8501H13.6724V15.9342ZM13.6724 7.71008H4.55746V2.57003H11.6469V4.11204C11.6469 4.43153 11.6486 4.62605 11.9633 4.62605H13.6724V7.71008ZM15.3814 10.6977C14.9097 10.6977 14.5269 10.3092 14.5269 9.83035C14.5269 9.35112 14.9097 8.96297 15.3814 8.96297C15.8532 8.96297 16.236 9.35112 16.236 9.83035C16.236 10.3092 15.8532 10.6977 15.3814 10.6977Z"
      fill="currentColor"
    />
  </svg>
</template>
